import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export interface Insurances {
  scwSelected: boolean
  paiSelected: boolean
}

export interface BookingFlowState {
  bookingFlowInProgress: boolean
  bookingFlowStep: number
  bookingCreationPending: boolean
  pickup: {
    pickupDate: string | null
    returnDate: string | null
    hasErrors: boolean
    timeSelected: boolean
  }
  insurances: Insurances
  overview: {
    included: string[]
    added: {
      id: string
      title: string
    }[]
    estimatedTotalCost: {
      insurancePrice: string
      additionalPrice: string
    }
    agreed: boolean
  }
}

const initialState: BookingFlowState = {
  bookingFlowInProgress: false,
  bookingFlowStep: 0,
  bookingCreationPending: false,
  pickup: {
    pickupDate: null,
    returnDate: null,
    hasErrors: false,
    timeSelected: false,
  },
  insurances: {
    scwSelected: false,
    paiSelected: false,
  },
  overview: {
    included: [],
    added: [],
    estimatedTotalCost: {
      insurancePrice: '0 SEK',
      additionalPrice: '0 SEK',
    },
    agreed: false,
  },
}

const bookingFlowSlice: Slice<BookingFlowState> = createSlice({
  name: 'booking-flow',
  initialState,
  reducers: {
    setBookingFlowInProgress: (state, action: PayloadAction<boolean>) => {
      state.bookingFlowInProgress = action.payload
    },
    setBookingFlowStep: (state, action: PayloadAction<number>) => {
      state.bookingFlowStep = action.payload
    },
    setBookingCreationPending: (state, action: PayloadAction<boolean>) => {
      state.bookingCreationPending = action.payload
    },
    setPickupDate: (state, action: PayloadAction<string>) => {
      state.pickup.pickupDate = action.payload
    },
    setReturnDate: (state, action: PayloadAction<string>) => {
      state.pickup.returnDate = action.payload
    },
    setPickupHasErrors: (state, action: PayloadAction<boolean>) => {
      state.pickup.hasErrors = action.payload
    },
    setTimeSelected: (state, action: PayloadAction<boolean>) => {
      state.pickup.timeSelected = action.payload
    },
    setScwSelected: (state, action: PayloadAction<boolean>) => {
      state.insurances.scwSelected = action.payload
    },
    setPaiSelected: (state, action: PayloadAction<boolean>) => {
      state.insurances.paiSelected = action.payload
    },
    setIncluded: (state, action: PayloadAction<string[]>) => {
      state.overview.included = action.payload
    },
    setAdded: (state, action: PayloadAction<{ type: 'ADD' | 'REMOVE'; title: string; id: string }>) => {
      const { type, title, id } = action.payload
      if (type === 'ADD') {
        if (!state.overview.added.some((item) => item.id === id)) {
          state.overview.added.push({ id, title })
        }
      } else if (type === 'REMOVE') {
        state.overview.added = state.overview.added.filter((item) => item.id !== id)
      }
    },
    setEstimatedTotalCost: (state, action: PayloadAction<{ type: 'insurance' | 'additional'; price: string }>) => {
      if (action.payload.type === 'insurance') {
        state.overview.estimatedTotalCost.insurancePrice = action.payload.price
      } else if (action.payload.type === 'additional') {
        state.overview.estimatedTotalCost.additionalPrice = action.payload.price
      }
    },
    setAgreed: (state, action: PayloadAction<boolean>) => {
      state.overview.agreed = action.payload
    },
    resetBookingFlow: () => initialState,
  },
})

export const {
  setBookingFlowInProgress,
  setBookingFlowStep,
  setBookingCreationPending,
  setPickupDate,
  setReturnDate,
  setPickupHasErrors,
  setTimeSelected,
  setScwSelected,
  setPaiSelected,
  setAdded,
  setIncluded,
  setEstimatedTotalCost,
  setAgreed,
  resetBookingFlow,
} = bookingFlowSlice.actions

export default bookingFlowSlice.reducer
